import { Link } from 'gatsby';
import React from 'react';

const Breadcrumbs = class extends React.Component {
  getLink(segment) {
    return this.props.pathname.slice(0, this.props.pathname.indexOf(segment) + segment.length);
  }

  getDisplayName(segment) {
    const words = segment.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(" ");
  }

  showBreadcrumbs() {
    const segments = this.props.pathname?.split("/").filter(Boolean);
    return segments?.length > 1 && segments[0] !== "news";
  }

  render() {
    if (this.showBreadcrumbs()) {
      const pathSegments = this.props.pathname  
        .split("/")
        .filter(Boolean)
        .map((segment) => {
          return {
            path: this.getLink(segment),
            displayName: this.getDisplayName(segment)
          }
        });  
      const currentPath = pathSegments.pop();
      const pathsView = pathSegments.map((segment) => {
        return(
          <li className="mt-0" key={segment.path}>
            <Link to={segment.path}>{segment.displayName}</Link>
          </li>
        )
      });

      return (
        <nav className="breadcrumb has-succeeds-separator is-medium is-size-6-mobile" aria-label="breadcrumbs">
          <ul>
            {pathsView}
            <li className="is-active mt-0"><a href={currentPath.path} aria-current="page">{currentPath.displayName}</a></li>
          </ul>
        </nav>
      )
    } else {
      return null;
    }
  }
}

export default Breadcrumbs

import React from 'react'
import PropTypes from 'prop-types'

const PageTitle = ({ title }) => {  
  return (
    <h2 className="has-text-weight-semibold is-size-4-mobile is-size-3-tablet is-size-2-widescreen">
      {title}
    </h2>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string,
}

export default PageTitle
